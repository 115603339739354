import React from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import style from "./Footer.module.css";
import logo from "../Image/logo.png";

function Footer() {
  const handleClicked = (url) => {
    window.open(url);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={style.footerContainer}>
      <div className={style.footerWrapper}>
        {/* Logo and About Section */}
        <div className={style.logoSection}>
          <Link to="/" onClick={scrollToTop}>
            <img className={style.logo} src={logo} alt="Easyryt Logo" />
          </Link>
          <p className={style.aboutText}>
            Easyryt Software Solutions offers the tools you require to
            revolutionize & expedite your business. We stand as a prominent
            software development enterprise with over a decade of experience in
            crafting cutting-edge resolutions.
          </p>
        </div>

        {/* Services Section */}
        <div className={style.linksSection}>
          <h4 className={style.sectionTitle}>Services</h4>
          <ul className={style.linkList}>
            <li>
              <Link to="/Mobile-App-Development" onClick={scrollToTop}>
                Mobile App Development
              </Link>
            </li>
            <li>
              <Link
                to="/IOS-Application-Development-Services"
                onClick={scrollToTop}
              >
                IOS Development
              </Link>
            </li>
            <li>
              <Link
                to="/Android-Application-Development-Services"
                onClick={scrollToTop}
              >
                Android Development
              </Link>
            </li>
            <li>
              <Link to="/Web-Application-Development" onClick={scrollToTop}>
                Web App Development
              </Link>
            </li>
          </ul>
        </div>

        {/* Company Section */}
        <div className={style.linksSection}>
          <h4 className={style.sectionTitle}>Company</h4>
          <ul className={style.linkList}>
            <li>
              <Link to="/About-Us" onClick={scrollToTop}>
                About Easyryt
              </Link>
            </li>
            <li>
              <Link to="/Contact-Us" onClick={scrollToTop}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/PrivacyPolicy" onClick={scrollToTop}>
                Privacy Policy
              </Link>
            </li>
            <li>&nbsp;</li>
          </ul>
        </div>

        {/* Contact Info Section */}
        <div className={style.contactSection}>
          <h4 className={style.sectionTitle}>Contact For Sales</h4>
          <ul className={style.contactList}>
            <li>Email: info@easyryt.com</li>
            <li>Phone: +91 9958280709</li>
            <li>Address: 1/3 East Patel Nagar, Delhi 110008</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>

      {/* Divider */}
      <div className={style.divider}></div>

      {/* Social Media Icons */}
      <div className={style.socialMedia}>
        <IconButton
          className={style.icon}
          onClick={() => handleClicked("https://www.instagram.com/easyryt/")}
        >
          <InstagramIcon fontSize="small" style={{ color: "white" }} />
        </IconButton>
        <IconButton
          className={style.icon}
          onClick={() =>
            handleClicked("https://www.facebook.com/easyrytofficial/")
          }
        >
          <FacebookIcon fontSize="small" style={{ color: "white" }} />
        </IconButton>
        <IconButton
          className={style.icon}
          onClick={() => handleClicked("https://twitter.com/EasyRyt")}
        >
          <TwitterIcon fontSize="small" style={{ color: "white" }} />
        </IconButton>
        <IconButton
          className={style.icon}
          onClick={() =>
            handleClicked("https://www.linkedin.com/company/easyryt")
          }
        >
          <LinkedInIcon fontSize="small" style={{ color: "white" }} />
        </IconButton>
        <IconButton
          className={style.icon}
          onClick={() => handleClicked("https://www.youtube.com/@easyryt")}
        >
          <YouTubeIcon fontSize="small" style={{ color: "white" }} />
        </IconButton>
      </div>
      <p>&copy; {new Date().getFullYear()} EasyRyt. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
